<template>
  <div>
    <b-flix-p>
      <b-flix-h1>
        {{
          $t('message.restore', {
            name: $tc('message.backup', 1)
          })
        }}
      </b-flix-h1>
    </b-flix-p>
    <b-flix-p>
      <b-flix-button variant="text-icon" :callback="() => $emit('close')">{{
        $t('message.back')
      }}</b-flix-button>
    </b-flix-p>
    <b-flix-card>
      <b-flix-h2>
        {{ $t('message.signIn') }}
      </b-flix-h2>
      <b-flix-p>
        <b-flix-input
          type="text"
          :label="$t('message.user')"
          :placeholder="userdata.user.username"
          disabled
        ></b-flix-input>
      </b-flix-p>
      <b-flix-p>
        <b-flix-input
          required
          type="password"
          :label="$t('message.password')"
          :callback="setPassword"
        ></b-flix-input>
      </b-flix-p>
      <b-flix-p>
        <b-flix-input
          type="text"
          disabled
          :label="$tc('message.backupID', 1)"
          :placeholder="backup"
        ></b-flix-input>
      </b-flix-p>
      <b-flix-p>
        <b-flix-alert variant="info">
          {{ $t('message.backupInfo') }}
        </b-flix-alert>
      </b-flix-p>
      <b-flix-p>
        <b-flix-button
          v-if="!loading"
          variant="contained-green"
          :callback="checkLogin"
        >
          {{
            $t('message.restore', {
              name: $tc('message.backup', 1)
            })
          }}
        </b-flix-button>
        <b-flix-loader v-else></b-flix-loader>
      </b-flix-p>
    </b-flix-card>
  </div>
</template>
<script>
export default {
  name: 'restoreComponent',
  components: {},
  props: {
    backup: {
      type: String
    }
  },
  data() {
    return {
      userdata: this.$getUserVariables(),
      password: '',
      loading: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setPassword(val) {
      this.password = val
    },
    async checkLogin() {
      this.loading = true
      this.$flix_post({
        url: '/user/get_login',
        data: {
          login: btoa(this.userdata.user.username + ':' + this.password)
        },
        callback: async (ret) => {
          if (ret.data[0]) {
            await this.setBackup(ret.data[1].ID)
          } else {
            this.loading = false
          }
        }
      })
    },
    async setBackup(ID) {
      const formData = new FormData()
      formData.append('user', ID)
      formData.append('backup', this.backup)

      await fetch(
        'https://backupserver.bookingflix.com/api/restoring',
        {
          method: 'POST',
          body: formData
        }
      ).then((ret) => ret.json())

      this.loading = false
      this.$emit('save')
      return true
    }
  }
}
</script>
<style lang="sass" scoped></style>
